<div class="px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="inline-flex w-full">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl inline-flex w-full">
        <span class="place-self-end">Alle Verkäufe</span>
      </h1>
    </div>
  </div>
  <div class="flex flex-col md:flex-row gap-4 overflow-x-auto">
    <input type="date" [(ngModel)]="date" (ngModelChange)="LoadData()">
    <select [(ngModel)]="composition_filter" (ngModelChange)="OrderFilter()">
      <option selected [ngValue]="undefined">Alle</option>
      <option *ngFor="let composition of GetAllCompositions()" [value]="composition.id">{{ composition.name }}</option>
    </select>
    <select [(ngModel)]="location_filter" (ngModelChange)="OrderFilter()">
      <option selected [ngValue]="undefined">Alle</option>
      <option *ngFor="let composition of GetAllLocations()" [value]="composition.id">{{ composition.name }}</option>
    </select>
  </div>
  <app-loading *ngIf="orders == undefined"></app-loading>
  <div>
    <div class="mt-6 overflow-hidden border-t border-gray-100">
      <div class="mx-auto max-w-7xl">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none overflow-x-auto">
          <table class="w-full text-left">
            <thead class="sr-only">
            <tr>
              <th>Amount</th>
              <th class="sm:table-cell">Client</th>
              <th>More details</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let order of filtered_orders" class="border-b border-gray-300">
              <td class="relative py-2 pr-6">
                <div class="flex gap-x-6">
                  <div class="flex-auto">
                    <div class="flex items-start gap-x-3">
                      <div class="inline-flex">
                        <svg *ngIf="order.bookingType == 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                             fill="currentColor" class="bi bi-cash-coin mr-2 place-self-center" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                                d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
                          <path
                            d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
                          <path
                            d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
                          <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
                        </svg>
                        <svg *ngIf="order.bookingType == 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                             fill="currentColor" class="bi bi-credit-card-2-back-fill mr-2 place-self-center"
                             viewBox="0 0 16 16">
                          <path
                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z"/>
                        </svg>
                        <svg *ngIf="order.bookingType == 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                             fill="currentColor" class="bi bi-person-circle mr-2 place-self-center" viewBox="0 0 16 16">
                          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                          <path fill-rule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                        </svg>
                        <div class="text-sm font-medium leading-6 text-gray-900">{{ order.total | currency : "EUR" }}
                        </div>
                      </div>
                      <div *ngIf="order.active"
                           class="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20">
                        Aktiv
                      </div>
                      <div *ngIf="!order.active"
                           class="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-red-700 bg-red-50 ring-red-600/20">
                        Unsichtbar
                      </div>
                    </div>
                    <div class="mt-1 text-xs leading-5 text-gray-500">
                      {{ (order.created) | date : "dd.MM.YYYY, HH:mm" }} Uhr
                    </div>
                  </div>
                </div>
              </td>
              <td class="py-2 sm:table-cell">
                <a (click)="ShowDrinks(order)"
                   class="text-sm leading-6 text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Getränke
                  anzeigen</a>
                <div class="mt-1 text-xs leading-5 text-gray-500">{{ order.location.name }}
                  ({{ order.configuration.name }})
                </div>
              </td>
              <td class="py-2 text-right">
                <div class="flex justify-end">
                  <a
                    [ngClass]="{'pointer-events-none opacity-50' : !PermissionService.CheckPermission(PermissionService.Booking_Order_SetActiveState())}"
                    (click)="SetActiveState(order, false)" *ngIf="order.active && order.canBeDeactivated"
                    class="text-sm font-medium leading-6 text-red-600 hover:text-red-500 cursor-pointer">Deaktivieren</a>
                </div>
                <div class="mt-1 text-xs leading-5 text-gray-500">{{ order.id }}</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <app-error-banner [(error)]="error"></app-error-banner>
</div>

<div *ngIf="order_selected" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto lg:pl-72">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div class="">
          <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
              <h1 class="text-base font-semibold leading-6 text-gray-900">Verkauf</h1>
              <a [href]="order_selected.receipt" target="_blank"
                 *ngIf="order_selected.receipt"
                 class="text-theme-primary-700 hover:text-theme-primary-900 select-none text-center ml-auto">
                <span>Beleg anzeigen</span>
              </a>
              <p class="mt-2 text-sm text-gray-700">{{ order_selected.location.name }}
                ({{ order_selected.configuration.name }})</p>
              <p class="mt-0 text-sm text-gray-700">{{ (order_selected.created) | date : "dd.MM.YYYY, HH:mm" }} Uhr</p>
              <p class="mt-2 text-sm text-gray-400">{{ order_selected.id }}</p>
            </div>
          </div>
          <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead>
                  <tr>
                    <th scope="col"
                        class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Getränk
                    </th>
                    <th scope="col"
                        class="whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-gray-900">Menge
                    </th>
                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      P.p.E.
                    </th>
                    <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Preis
                    </th>
                  </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                  <tr *ngFor="let composition of order_selected.compositions">
                    <td
                      class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ composition.composition.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 text-center">{{ composition.quantity }}
                    </td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-600"
                        [class.text-red-600]="composition.composition.oPrice">
                      {{
                        (composition.composition.oPrice ?? composition.composition.price) | currency: "EUR"
                      }}
                    </td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                      {{
                        (composition.composition.oPrice ?? composition.composition.price) * composition.quantity | currency: "EUR"
                      }}
                    </td>
                  </tr>
                  <!-- More transactions... -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 sm:mt-6">
          <button (click)="order_selected = undefined" type="button"
                  class="inline-flex w-full justify-center rounded-md bg-theme-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700">
            Schließen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

